<template>
  <div>
    <v-row no-glutters class="body-row">
      <v-col cols="12" md="7" class="left-cols pr-md-8" :class="cardloader ? 'poi-n' : ''">
        <p class="subtitle-2 font-weight-bold mb-2 mb-md-5 txt-666">
          <v-btn :disabled="cardloader" to="/email" icon><v-icon>mdi-arrow-left</v-icon></v-btn> Back
        </p>
        <p class="headline font-weight-black mb-2">Welcome to Zebu</p>

        <v-card width="100%" class="text-center py-16 elevation-0" color="transparent">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
            <path
              d="M40 1.25C18.6337 1.25 1.25 18.6337 1.25 40C1.25 61.3662 18.6337 78.75 40 78.75C61.3662 78.75 78.75 61.3662 78.75 40C78.75 18.6337 61.3662 1.25 40 1.25ZM40 76.25C20.0125 76.25 3.75 59.9875 3.75 40C3.75 20.0125 20.0125 3.75 40 3.75C59.9875 3.75 76.25 20.0125 76.25 40C76.25 59.9875 59.9875 76.25 40 76.25Z"
              fill="#666666"
            />
            <path d="M40 15C41.3807 15 42.5 13.8807 42.5 12.5C42.5 11.1193 41.3807 10 40 10C38.6193 10 37.5 11.1193 37.5 12.5C37.5 13.8807 38.6193 15 40 15Z" fill="#666666" />
            <path d="M67.5 42.5C68.8807 42.5 70 41.3807 70 40C70 38.6193 68.8807 37.5 67.5 37.5C66.1193 37.5 65 38.6193 65 40C65 41.3807 66.1193 42.5 67.5 42.5Z" fill="#666666" />
            <path d="M40 70C41.3807 70 42.5 68.8807 42.5 67.5C42.5 66.1193 41.3807 65 40 65C38.6193 65 37.5 66.1193 37.5 67.5C37.5 68.8807 38.6193 70 40 70Z" fill="#666666" />
            <path d="M12.5 42.5C13.8807 42.5 15 41.3807 15 40C15 38.6193 13.8807 37.5 12.5 37.5C11.1193 37.5 10 38.6193 10 40C10 41.3807 11.1193 42.5 12.5 42.5Z" fill="#666666" />
            <path d="M46.1064 44.3389C45.6189 45.0226 45.0226 45.6189 44.3389 46.1064L56.6164 58.3839L58.3839 56.6164L46.1064 44.3389Z" fill="#666666" />
            <path
              d="M44.8137 38.7209L55.5174 28.0172C56.4937 27.0409 56.4937 25.4584 55.5174 24.4822C54.5412 23.5059 52.9587 23.5059 51.9824 24.4822L41.2787 35.1859C40.3462 34.9372 39.6549 34.9372 38.7212 35.1859L25.5174 21.9822C24.5412 21.0059 22.9587 21.0059 21.9824 21.9822C21.0062 22.9584 21.0062 24.5409 21.9824 25.5172L35.1862 38.7209C35.0774 39.1322 34.9999 39.5547 34.9999 39.9997C34.9999 42.7572 37.2424 44.9997 39.9999 44.9997C42.7574 44.9997 44.9999 42.7572 44.9999 39.9997C44.9999 39.5547 44.9224 39.1322 44.8137 38.7209Z"
              fill="#666666"
            />
          </svg>

          <p class="subtitle-1 font-weight-regular txt-666 mt-5 mb-6">
            It takes you less than 10 minutes to complete the KYC process and start <br />
            trading and investing in zebu
          </p>
          <v-btn height="48px" @click="nextStage()" color="#000" class="white--text text-none rounded-pill elevation-0"
            ><span class="white--text subtitle-1 font-weight-medium px-6">Finish {{ rekycdata ? "RE-KYC" : "KYC" }} & Onboarding Process</span></v-btn
          >

          <p v-if="rekycdata" class="fs-12 txt-666 font-weight-regular mt-4"><v-icon color="#666" size="14">mdi-information-outline</v-icon> Due to all segments being inactive, you are performing <br />a re-kyc onboarding process.</p>
        </v-card>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img src="@/assets/onb1.svg" class="rgt-img" alt="rgt-img" />
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/api";

export default {
  data: () => ({
    rekycdata: false,
    cardloader: false,
  }),
  mounted() {
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        let p = response.data.data_type;
        if (response.data.data_type && response.data.app_status != "rejected" && p.stage == "onboard") {
          axiosThis.rekycdata = response.data.data_type.rekyc == "Yes" ? true : false;
          // axiosThis.$emit('clicked', 0)
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },
  methods: {
    nextStage() {
      this.$emit("clicked", "pan");
      // this.$router.push("/pan");
    },
  },
};
</script>
