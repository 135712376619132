import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  // apiKey: "AIzaSyC3Y1mR6BQTXejIqULP216aBPDebVfl4_Y",
  // authDomain: "zebu-ekcy.firebaseapp.com",
  // projectId: "zebu-ekcy",
  // storageBucket: "zebu-ekcy.appspot.com",
  // messagingSenderId: "1081755352404",
  // appId: "1:1081755352404:web:84a08441a4fd7150351cc1",
  // measurementId: "G-8F4NZEZ625"
  apiKey: "AIzaSyDaf1zRtxcFRueAhve9Snj0NI5_JJuCcwA",
  authDomain: "zebu-ekyc-v3.firebaseapp.com",
  projectId: "zebu-ekyc-v3",
  storageBucket: "zebu-ekyc-v3.appspot.com",
  messagingSenderId: "1027275828374",
  appId: "1:1027275828374:web:c97ee2ff926925e3b8e6b7",
  measurementId: "G-6ZQZ5GPJR8"
};

initializeApp(firebaseConfig);

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
