<template>
  <div class="no-scroll">
    <v-snackbar class="pt-6 pr-6" style="z-index: 6 !important" transition="slide-x-reverse-transition" v-model="snackbar" timeout="5000" :value="true" :color="snackbarclr" absolute outlined top right>
      <v-icon class="mr-2" :color="snackbarclr">mdi-alert-outline</v-icon>
      {{ snackmsgbar }}
      <v-icon @click="snackbar = false" class="float-right" :color="snackbarclr">mdi-close-circle</v-icon>
    </v-snackbar>

    <v-sheet class="text-center" v-if="btmsheet" height="100vh">
      <v-snackbar class="pt-6 pr-6" style="z-index: 6 !important" transition="slide-x-reverse-transition" v-model="snackbar" timeout="5000" :value="true" :color="snackbarclr" absolute outlined top right>
        <v-icon class="mr-2" :color="snackbarclr">mdi-alert-outline</v-icon>
        {{ snackmsgbar }}
        <v-icon @click="snackbar = false" class="float-right" :color="snackbarclr">mdi-close-circle</v-icon>
      </v-snackbar>
      <!-- <v-toolbar dense flat>
            <img src="@/assets/Zebu Logo.svg" width="90px">
  
            <v-spacer></v-spacer>
            <v-btn icon @click="btmsheet = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar> -->
      <v-divider></v-divider>
      <div class="py-3 px-3">
        <v-row no-glutters style="border-bottom: thin solid #2a2a2a30">
          <v-col cols="4" class="pa-0">
            <v-tabs
              v-model="btmltab"
              show-arrows
              @change="stageFind(), btmltab == 2 ? (btmrtab = 1) : btmltab == 3 ? (btmrtab = 2) : btmltab == 5 ? (btmrtab = 3) : btmltab == 6 ? (btmrtab = 4) : btmltab == 7 ? (btmrtab = 5) : btmltab == 8 ? (btmrtab = 6) : btmltab == 11 ? (btmrtab = 7) : (btmrtab = 0)"
            >
              <v-tab class="text-none">Mobile</v-tab>
              <v-tab class="text-none">Email</v-tab>
              <v-tab class="text-none">PAN</v-tab>

              <v-tab class="text-none">Digilocker</v-tab>
              <v-tab class="text-none">Personal</v-tab>
              <v-tab class="text-none">Bank</v-tab>

              <v-tab class="text-none">Segment</v-tab>
              <!-- <v-tab class="text-none">Declartaion</v-tab> -->
              <v-tab class="text-none">Signature</v-tab>
              <v-tab class="text-none">Selfie</v-tab>

              <v-tab class="text-none">Nominee</v-tab>
              <v-tab class="text-none">Other</v-tab>
              <v-tab class="text-none">KRA</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="8" class="pa-0">
            <v-tabs
              v-model="btmrtab"
              show-arrows
              @change="
                btmrtab == 1
                  ? btmltab == 2
                    ? (btmltab = 2)
                    : (btmltab = 3)
                  : btmrtab == 2
                  ? btmltab == 3
                    ? (btmltab = 3)
                    : (btmltab = 2)
                  : btmrtab == 3
                  ? (btmltab = 5)
                  : btmrtab == 4
                  ? (btmltab = 6)
                  : btmrtab == 5
                  ? (btmltab = 7)
                  : btmrtab == 6
                  ? (btmltab = 8)
                  : btmrtab == 7
                  ? (btmltab = 11)
                  : btmrtab == 8
                  ? (btmltab = 10)
                  : btmrtab == 9
                  ? (btmltab = 0)
                  : (btmrtab = 0),
                  stageFind()
              "
            >
              <v-tab class="text-none">e-sign document</v-tab>
              <v-tab class="text-none">PAN proof</v-tab>
              <v-tab class="text-none">Aadhar proof</v-tab>
              <v-tab class="text-none">Bank proof</v-tab>
              <v-tab class="text-none">Income proof</v-tab>
              <v-tab class="text-none">Signature proof</v-tab>
              <v-tab class="text-none">Selfie proof</v-tab>
              <v-tab class="text-none">KRA proof</v-tab>
              <v-tab class="text-none">Aadhar XML</v-tab>
              <v-tab class="text-none">raw</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row no-glutters>
          <v-col cols="4" style="height: calc(100vh - 52px); overflow: scroll; border-right: thin solid #2a2a2a30">
            <div v-if="btmltab == 0" class="text-left">
              <p class="grey--text mb-0">Mobile</p>
              <p class="black--text font-weight-bold">{{ btmdata.mobile_no ? btmdata.mobile_no : "-" }}</p>
              <p class="grey--text mb-0">Mobile verify</p>
              <p class="black--text font-weight-bold">{{ btmdata.mobileVerify ? btmdata.mobileVerify : "-" }}</p>
              <p class="grey--text mb-0">Mobile verify time</p>
              <p class="black--text font-weight-bold">{{ btmdata.mobileVerifyTime ? btmdata.mobileVerifyTime : "-" }}</p>
            </div>
            <div v-if="btmltab == 1" class="text-left">
              <p class="grey--text mb-0">Email</p>
              <p class="black--text font-weight-bold">{{ btmdata.client_email ? btmdata.client_email : "-" }}</p>
              <p class="grey--text mb-0">Email verify</p>
              <p class="black--text font-weight-bold">{{ btmdata.emailVerify ? (btmdata.emailVerify == "yes" ? btmdata.emailVerify : btmdata.emailVerify == "no" ? btmdata.emailVerify : JSON.parse(btmdata.emailVerify).email_verified == true ? "yes" : "no") : "-" }}</p>
              <p class="grey--text mb-0">Email verify time</p>
              <p class="black--text font-weight-bold">{{ btmdata.emailVerifyTime ? btmdata.emailVerifyTime : "-" }}</p>
              <!--    "email_dependency": "",
      "email_time_after_esign": "",
      "email_time_before_esign": "", -->
            </div>
            <div v-if="btmltab == 2" class="text-left">
              <p class="grey--text mb-0">Name in PAN</p>
              <p class="black--text font-weight-bold">{{ btmdata.client_name ? btmdata.client_name : "-" }}</p>
              <p class="grey--text mb-0">PAN number</p>
              <p class="black--text font-weight-bold">{{ btmdata.client_pan ? btmdata.client_pan : "-" }}</p>
              <p class="grey--text mb-0">Aadhar number</p>
              <p class="black--text font-weight-bold">{{ btmdata.aadharNumber ? btmdata.aadharNumber : "-" }}</p>
              <p class="grey--text mb-0">PAN / Aadhar - seeding status</p>
              <p class="black--text font-weight-bold">{{ btmdata.aadhar_seeding_status ? btmdata.aadhar_seeding_status : "-" }}</p>
              <p class="grey--text mb-0">PAN number verify</p>
              <p class="black--text font-weight-bold">{{ btmdata.panVerify ? btmdata.panVerify : "-" }}</p>
              <p class="grey--text mb-0">PAN number verify time</p>
              <p class="black--text font-weight-bold">{{ btmdata.panVerifyTime ? btmdata.panVerifyTime : "-" }}</p>
              <p class="grey--text mb-0">PAN proof type</p>
              <p class="black--text font-weight-bold">{{ btmdata.pan_is ? (btmdata.pan_is == "y" ? "Digitallocker download" : "Manual upload") : " - " }}</p>
              <p class="grey--text mb-0">PAN download response</p>
              <p class="black--text font-weight-bold">{{ btmdata.pandownresp ? btmdata.pandownresp : "-" }}</p>
            </div>
            <div v-if="btmltab == 3" class="text-left">
              <p class="grey--text mb-0">Aadhar number</p>
              <p class="black--text font-weight-bold">{{ btmdata.aadhar_no ? btmdata.aadhar_no : "-" }}</p>
              <p class="grey--text mb-0">Name in Aadhar</p>
              <p class="black--text font-weight-bold">{{ btmdata.adhar_name ? btmdata.adhar_name : "-" }}</p>
              <p class="grey--text mb-0">DOB in Aadhar</p>
              <p class="black--text font-weight-bold">{{ btmdata.adhar_dob ? btmdata.adhar_dob : "-" }}</p>
              <p class="grey--text mb-0">Gender in Aadhar</p>
              <p class="black--text font-weight-bold">{{ btmdata.adhar_gender ? btmdata.adhar_gender : "-" }}</p>
              <p class="grey--text mb-0">Father Name in Aadhar</p>
              <p class="black--text font-weight-bold">{{ btmdata.adhar_fathername ? btmdata.adhar_fathername : "-" }}</p>
              <p class="grey--text mb-0">Address in Aadhar</p>
              <p class="black--text font-weight-bold">{{ btmdata.aadhar_address ? btmdata.aadhar_address : "-" }}</p>
              <p class="grey--text mb-0">Aadhar verify</p>
              <p class="black--text font-weight-bold">{{ btmdata.digilockerVerify ? btmdata.digilockerVerify : "-" }}</p>
              <p class="grey--text mb-0">Aadhar verify time</p>
              <p class="black--text font-weight-bold">{{ btmdata.digilockerVerifyTime ? btmdata.digilockerVerifyTime : "-" }}</p>
            </div>
            <div v-if="btmltab == 4" class="text-left">
              <p class="grey--text mb-0">Father name</p>
              <p class="black--text font-weight-bold">{{ btmdata.fathername ? btmdata.fathername : "-" }}</p>
              <p class="grey--text mb-0">Mother name</p>
              <p class="black--text font-weight-bold">{{ btmdata.mothername ? btmdata.mothername : "-" }}</p>
              <p class="grey--text mb-0">Marital status</p>
              <p class="black--text font-weight-bold">{{ btmdata.marital_status ? btmdata.marital_status : "-" }}</p>
              <p class="grey--text mb-0">Trading experience</p>
              <p class="black--text font-weight-bold">{{ btmdata.TradingExperience ? btmdata.TradingExperience : "-" }}</p>
              <p class="grey--text mb-0">Annual Income</p>
              <p class="black--text font-weight-bold">{{ btmdata.annual_income ? btmdata.annual_income : "-" }}</p>
              <p class="grey--text mb-0">Occupation</p>
              <p class="black--text font-weight-bold">{{ btmdata.Occupation ? btmdata.Occupation : "-" }}</p>
            </div>
            <div v-if="btmltab == 5" class="text-left">
              <p class="grey--text mb-0">Bank AC no</p>
              <p class="black--text font-weight-bold">{{ btmdata.BankAccountNumber ? btmdata.BankAccountNumber : "-" }}</p>
              <p class="grey--text mb-0">Bank IFSC code</p>
              <p class="black--text font-weight-bold">{{ btmdata.IFSC ? btmdata.IFSC : "-" }}</p>
              <p class="grey--text mb-0">Bank MICR code</p>
              <p class="black--text font-weight-bold">{{ btmdata.MICR ? btmdata.MICR : "-" }}</p>
              <p class="grey--text mb-0">Bank Account type</p>
              <p class="black--text font-weight-bold">{{ btmdata.bank_account_type ? btmdata.bank_account_type : "-" }}</p>
              <p class="grey--text mb-0">Bank name</p>
              <p class="black--text font-weight-bold">{{ btmdata.BankName ? btmdata.BankName : "-" }}</p>
              <p class="grey--text mb-0">Bank address</p>
              <p class="black--text font-weight-bold">{{ btmdata.BankAddress ? btmdata.BankAddress : "-" }}</p>
              <p class="grey--text mb-0">Bank branch name</p>
              <p class="black--text font-weight-bold">{{ btmdata.BranchName ? btmdata.BranchName : "-" }}</p>
              <p class="grey--text mb-0">Bank verify</p>
              <p class="black--text font-weight-bold">{{ btmdata.bankverify ? btmdata.bankverify : "-" }}</p>
              <p class="grey--text mb-0">Penny drop verify</p>
              <p class="black--text font-weight-bold">{{ btmdata.peenydropVerify ? btmdata.peenydropVerify : "-" }}</p>
              <p class="grey--text mb-0">Penny drop verify time</p>
              <p class="black--text font-weight-bold">{{ btmdata.peenydropVerifyTime ? btmdata.peenydropVerifyTime : "-" }}</p>
              <p class="grey--text mb-0">Penny drop status</p>
              <p class="black--text font-weight-bold">{{ btmdata.pennyresponse ? btmdata.pennyresponse.results.account_status : "-" }}</p>
              <p class="grey--text mb-0">Penny drop reg name</p>
              <p class="black--text font-weight-bold">{{ btmdata.pennyresponse ? btmdata.pennyresponse.results.registered_name : "-" }}</p>
              <p class="grey--text mb-0">Bank proof type</p>
              <p class="black--text font-weight-bold">{{ btmdata.bankProofType ? btmdata.bankProofType : "-" }}</p>
            </div>
            <div v-if="btmltab == 6" class="text-left">
              <!-- <p class="grey--text mb-0">Future and Options</p>
                <p class="black--text font-weight-bold">{{ btmdata.fno ? btmdata.fno : '-' }}</p> -->
              <p class="grey--text mb-0">Segments selection</p>
              <p class="black--text font-weight-bold">{{ btmdata.segment ? btmdata.segment : "-" }}</p>
              <p class="grey--text mb-0">Segments proof type</p>
              <p class="black--text font-weight-bold">{{ btmdata.incometype ? btmdata.incometype : "-" }}</p>

              <!-- <v-chip-group v-if="btmdata.segment" column>
                  <v-chip class="black--text font-weight-bold" small v-for="(l, k) in btmdata.segment" :key="k">
                    {{ l }}
                  </v-chip>
                </v-chip-group> -->
            </div>
            <div v-if="btmltab == 7" class="text-left">
              <p class="grey--text mb-0">Signature type</p>
              <p class="black--text font-weight-bold">{{ btmdata.data_type ? (btmdata.data_type.signtype == "digital" ? "Digital signed" : "Manual upload") : "-" }}</p>
            </div>
            <!--<div v-if="btmltab == 8" class="text-left">

              </div> -->
            <div v-if="btmltab == 8" class="text-left">
              <p class="grey--text mb-0">IP address</p>
              <p class="black--text font-weight-bold">{{ btmdata.IPaddress ? btmdata.IPaddress : "-" }}</p>
              <p class="grey--text mb-0">User location on selfie</p>
              <p class="black--text font-weight-bold">
                <a :href="btmdata.userLocationData ? btmdata.userLocationData : ''" target="_blank">{{ btmdata.userLocationData ? btmdata.userLocationData : "-" }}</a>
              </p>
            </div>
            <div v-if="btmltab == 9" class="text-left">
              <p class="grey--text mb-0">Nominee for investment</p>
              <p class="black--text font-weight-bold">{{ btmdata.nominee_check ? (btmdata.nominee_check == "no" ? "No, i will do it later" : btmdata.nominee_check) : "-" }}</p>
              <v-expansion-panels class="elevation-0">
                <v-expansion-panel class="exp-sty">
                  <v-expansion-panel-header class="px-0" :class="btmdata.nominee1_name ? (btmdata.nominee1_name.length > 0 ? 'font-weight-bold' : 'font-weight-regular') : ''">1st Nominee </v-expansion-panel-header>
                  <v-expansion-panel-content class="px-0 text-left">
                    <p class="grey--text mb-0">Nominee name</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee1_name ? btmdata.nominee1_name : "-" }}</p>
                    <p class="grey--text mb-0">Nominee relation</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee1_relation ? btmdata.nominee1_relation : "-" }}</p>
                    <p class="grey--text mb-0">Nominee percentage</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee1_percentage ? btmdata.nominee1_percentage : "-" }}</p>
                    <p class="grey--text mb-0">Nominee DOB</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee1_DOB ? btmdata.nominee1_DOB : "-" }}</p>
                    <p class="grey--text mb-0">Nominee address</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee1_address1 ? btmdata.nominee1_address1 : "-" }}</p>
                    <p class="grey--text mb-0">Nominee city</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee1_city ? btmdata.nominee1_city : "-" }}</p>
                    <p class="grey--text mb-0">Nominee pincode</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee1_pincode ? btmdata.nominee1_pincode : "-" }}</p>
                    <p class="grey--text mb-0">Nominee state</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee1_state ? btmdata.nominee1_state : "-" }}</p>
                    <p class="grey--text mb-0">Nominee country</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee1_country ? btmdata.nominee1_country : "-" }}</p>
                    <!-- <p class="grey--text mb-0">Nominee email</p>
                      <p class="black--text font-weight-bold">{{ btmdata.nominee1_email ?
                        btmdata.nominee1_email : '-' }}</p>
                      <p class="grey--text mb-0">Nominee mobile</p>
                      <p class="black--text font-weight-bold">{{ btmdata.nominee1_mobile ?
                        btmdata.nominee1_mobile : '-' }}</p> -->
                    <p class="grey--text mb-0">guardian name</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_name ? btmdata.guardian_name : "-" }}</p>
                    <p class="grey--text mb-0">guardian relation</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_relation ? btmdata.guardian_relation : "-" }}</p>
                    <p class="grey--text mb-0">guardian DOB</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_dob ? btmdata.guardian_dob : "-" }}</p>
                    <p class="grey--text mb-0">guardian address</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_address ? btmdata.guardian_address : "-" }}</p>
                    <p class="grey--text mb-0">guardian city</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_city ? btmdata.guardian_city : "-" }}</p>
                    <p class="grey--text mb-0">guardian state</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_state ? btmdata.guardian_state : "-" }}</p>
                    <p class="grey--text mb-0">guardian pincode</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_pincode ? btmdata.guardian_pincode : "-" }}</p>
                    <p class="grey--text mb-0">guardian country</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_country ? btmdata.guardian_country : "-" }}</p>
                    <!-- <p class="grey--text mb-0">guardian email</p>
                      <p class="black--text font-weight-bold">{{ btmdata.guardian_email ?
                        btmdata.guardian_email : '-' }}</p>
                      <p class="grey--text mb-0">guardian mobile</p>
                      <p class="black--text font-weight-bold">{{ btmdata.guardian_mobile ?
                        btmdata.guardian_mobile : '-' }}</p> -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="exp-sty" :class="btmdata.nominee2_name ? (btmdata.nominee2_name.length > 0 ? 'font-weight-bold' : 'font-weight-regular') : ''">
                  <v-expansion-panel-header class="px-0">2nd Nominee </v-expansion-panel-header>
                  <v-expansion-panel-content class="px-0 text-left">
                    <p class="grey--text mb-0">Nominee name</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee2_name ? btmdata.nominee2_name : "-" }}</p>
                    <p class="grey--text mb-0">Nominee relation</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee2_relation ? btmdata.nominee2_relation : "-" }}</p>
                    <p class="grey--text mb-0">Nominee percentage</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee2_percentage ? btmdata.nominee2_percentage : "-" }}</p>
                    <p class="grey--text mb-0">Nominee DOB</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee2_DOB ? btmdata.nominee2_DOB : "-" }}</p>
                    <p class="grey--text mb-0">Nominee address</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee2_address1 ? btmdata.nominee2_address1 : "-" }}</p>
                    <p class="grey--text mb-0">Nominee city</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee2_city ? btmdata.nominee2_city : "-" }}</p>
                    <p class="grey--text mb-0">Nominee pincode</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee2_pincode ? btmdata.nominee2_pincode : "-" }}</p>
                    <p class="grey--text mb-0">Nominee state</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee2_state ? btmdata.nominee2_state : "-" }}</p>
                    <p class="grey--text mb-0">Nominee country</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee2_country ? btmdata.nominee2_country : "-" }}</p>
                    <!-- <p class="grey--text mb-0">Nominee email</p>
                      <p class="black--text font-weight-bold">{{ btmdata.nominee2_email ?
                        btmdata.nominee2_email : '-' }}</p>
                      <p class="grey--text mb-0">Nominee mobile</p>
                      <p class="black--text font-weight-bold">{{ btmdata.nominee2_mobile ?
                        btmdata.nominee2_mobile : '-' }}</p> -->
                    <p class="grey--text mb-0">guardian name</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_name2 ? btmdata.guardian_name2 : "-" }}</p>
                    <p class="grey--text mb-0">guardian relation</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_relation2 ? btmdata.guardian_relation2 : "-" }}</p>
                    <p class="grey--text mb-0">guardian DOB</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_dob2 ? btmdata.guardian_dob2 : "-" }}</p>
                    <p class="grey--text mb-0">guardian address</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_address2 ? btmdata.guardian_address2 : "-" }}</p>
                    <p class="grey--text mb-0">guardian city</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_city2 ? btmdata.guardian_city2 : "-" }}</p>
                    <p class="grey--text mb-0">guardian state</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_state2 ? btmdata.guardian_state2 : "-" }}</p>
                    <p class="grey--text mb-0">guardian pincode</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_pincode2 ? btmdata.guardian_pincode2 : "-" }}</p>
                    <p class="grey--text mb-0">guardian country</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_country2 ? btmdata.guardian_country2 : "-" }}</p>
                    <!-- <p class="grey--text mb-0">guardian email</p>
                      <p class="black--text font-weight-bold">{{ btmdata.guardian_email2 ?
                        btmdata.guardian_email2 : '-' }}</p>
                      <p class="grey--text mb-0">guardian mobile</p>
                      <p class="black--text font-weight-bold">{{ btmdata.guardian_mobile2 ?
                        btmdata.guardian_mobile2 : '-' }}</p> -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="exp-sty" :class="btmdata.nominee3_name ? (btmdata.nominee3_name.length > 0 ? 'font-weight-bold' : 'font-weight-regular') : ''">
                  <v-expansion-panel-header class="px-0">3rd Nominee </v-expansion-panel-header>
                  <v-expansion-panel-content class="px-0 text-left">
                    <p class="grey--text mb-0">Nominee name</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee3_name ? btmdata.nominee3_name : "-" }}</p>
                    <p class="grey--text mb-0">Nominee relation</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee3_relation ? btmdata.nominee3_relation : "-" }}</p>
                    <p class="grey--text mb-0">Nominee percentage</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee3_percentage ? btmdata.nominee3_percentage : "-" }}</p>
                    <p class="grey--text mb-0">Nominee DOB</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee3_DOB ? btmdata.nominee3_DOB : "-" }}</p>
                    <p class="grey--text mb-0">Nominee address</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee3_address1 ? btmdata.nominee3_address1 : "-" }}</p>
                    <p class="grey--text mb-0">Nominee city</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee3_city ? btmdata.nominee3_city : "-" }}</p>
                    <p class="grey--text mb-0">Nominee pincode</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee3_pincode ? btmdata.nominee3_pincode : "-" }}</p>
                    <p class="grey--text mb-0">Nominee state</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee3_state ? btmdata.nominee3_state : "-" }}</p>
                    <p class="grey--text mb-0">Nominee country</p>
                    <p class="black--text font-weight-bold">{{ btmdata.nominee3_country ? btmdata.nominee3_country : "-" }}</p>
                    <!-- <p class="grey--text mb-0">Nominee email</p>
                      <p class="black--text font-weight-bold">{{ btmdata.nominee3_email ?
                        btmdata.nominee3_email : '-' }}</p>
                      <p class="grey--text mb-0">Nominee mobile</p>
                      <p class="black--text font-weight-bold">{{ btmdata.nominee3_mobile ?
                        btmdata.nominee3_mobile : '-' }}</p> -->
                    <p class="grey--text mb-0">guardian name</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_name3 ? btmdata.guardian_name3 : "-" }}</p>
                    <p class="grey--text mb-0">guardian relation</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_relation3 ? btmdata.guardian_relation3 : "-" }}</p>
                    <p class="grey--text mb-0">guardian DOB</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_dob3 ? btmdata.guardian_dob3 : "-" }}</p>
                    <p class="grey--text mb-0">guardian address</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_address3 ? btmdata.guardian_address3 : "-" }}</p>
                    <p class="grey--text mb-0">guardian city</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_city3 ? btmdata.guardian_city3 : "-" }}</p>
                    <p class="grey--text mb-0">guardian state</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_state3 ? btmdata.guardian_state3 : "-" }}</p>
                    <p class="grey--text mb-0">guardian pincode</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_pincode3 ? btmdata.guardian_pincode3 : "-" }}</p>
                    <p class="grey--text mb-0">guardian country</p>
                    <p class="black--text font-weight-bold">{{ btmdata.guardian_country3 ? btmdata.guardian_country3 : "-" }}</p>
                    <!-- <p class="grey--text mb-0">guardian email</p>
                      <p class="black--text font-weight-bold">{{ btmdata.guardian_email3 ?
                        btmdata.guardian_email3 : '-' }}</p>
                      <p class="grey--text mb-0">guardian mobile</p>
                      <p class="black--text font-weight-bold">{{ btmdata.guardian_mobile3 ?
                        btmdata.guardian_mobile3 : '-' }}</p> -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div v-if="btmltab == 10" class="text-left">
              <p class="grey--text mb-0">Client Id</p>
              <p class="black--text font-weight-bold">{{ btmdata.client_id ? btmdata.client_id : "-" }}</p>
              <p class="grey--text mb-0">RM-code</p>
              <p class="black--text font-weight-bold">{{ btmdata.rmcode ? btmdata.rmcode : "-" }}</p>
              <p class="grey--text mb-0">Login type</p>
              <p class="black--text font-weight-bold">{{ btmdata.login_type ? btmdata.login_type : "-" }}</p>
              <p class="grey--text mb-0">Coupon code</p>
              <p class="black--text font-weight-bold">{{ btmdata.cuponcode ? btmdata.cuponcode : "-" }}</p>
              <p class="grey--text mb-0">Unused funds to be settled after every _____?</p>
              <p class="black--text font-weight-bold">{{ btmdata.declaration ? btmdata.declaration.days : "-" }}</p>
            </div>
            <div v-if="btmltab == 11" class="text-left">
              <p class="grey--text mb-0">KRA Esigned</p>
              <p class="black--text font-weight-bold">{{ btmdata.kra_esigned ? btmdata.kra_esigned : "-" }}</p>
              <p class="grey--text mb-0">KRA Esigned time</p>
              <p class="black--text font-weight-bold">{{ btmdata.kra_esigned_time ? btmdata.kra_esigned_time : "-" }}</p>
              <p class="grey--text mb-0">KRA</p>
              <p class="black--text font-weight-bold">{{ btmdata.kra ? btmdata.kra : "-" }}</p>
              <p class="grey--text mb-0">Complete KRA</p>
              <p class="black--text font-weight-bold">{{ btmdata.complete_kra ? btmdata.complete_kra : "-" }}</p>
              <p class="grey--text mb-0">KRA Document Uploaded</p>
              <p class="black--text font-weight-bold">{{ btmdata.kraDocumentUploaded ? btmdata.kraDocumentUploaded : "-" }}</p>
              <p class="grey--text mb-0">KRA Link</p>
              <p class="black--text font-weight-bold">{{ btmdata.kra_link ? btmdata.kra_link : "-" }}</p>
              <p class="grey--text mb-0">KRA Hash</p>
              <p class="black--text font-weight-bold">{{ btmdata.kra_hash ? btmdata.kra_hash : "-" }}</p>
              <p class="grey--text mb-0">KRA Transaction Id</p>
              <p class="black--text font-weight-bold">{{ btmdata.transaction_id_kra ? btmdata.transaction_id_kra : "-" }}</p>
              <v-divider class="mb-4"></v-divider>
              <div v-if="btmdata.kradata && btmdata.kradata.cor_addr">
                <div v-for="(n, l) in btmdata.kradata.cor_addr" :key="l">
                  <p class="grey--text mb-0">{{ l ? l : "-" }}</p>
                  <p class="black--text font-weight-bold">{{ n ? n : "-" }}</p>
                </div>
              </div>
              <v-divider class="mb-4"></v-divider>
              <div v-if="btmdata.kradata && btmdata.kradata.per_addr">
                <div v-for="(n, l) in btmdata.kradata.per_addr" :key="l">
                  <p class="grey--text mb-0">{{ l ? l : "-" }}</p>
                  <p class="black--text font-weight-bold">{{ n ? n : "-" }}</p>
                </div>
              </div>
              <v-divider class="mb-4"></v-divider>
              <div v-if="btmdata.kradata && btmdata.kradata.personal_details">
                <div v-for="(n, l) in btmdata.kradata.personal_details" :key="l">
                  <p class="grey--text mb-0">{{ l ? l : "-" }}</p>
                  <p class="black--text font-weight-bold">{{ n ? n : "-" }}</p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="8" style="height: calc(100vh - 52px); overflow: scroll" class="pa-0">
            <v-tabs-items v-model="btmrtab">
              <v-tab-item>
                <iframe style="border: thin dashed #cfd8dc !important; border-radius: 0; height: calc(100vh - 102px)" :src="btmdata.e_signed_pdf_path ? btmdata.e_signed_pdf_path : ''" width="100%"></iframe>
              </v-tab-item>
              <v-tab-item style="position: relative">
                <iframe v-if="btmdata.pan_is == 'y'" style="border: none; border-radius: 0; height: calc(100vh - 102px)" :src="btmdata.uploadPAN ? btmdata.uploadPAN : ''" width="100%"></iframe>
                <img
                  v-else
                  :style="`transform: rotate(${panrot.rot}deg) scaleX(${panrot.flip == 0 ? -1 : 1}) !important; padding: ${panrot.pad / 3}px ${panrot.pad}px !important; ${
                    panrot.rot == 90 ? 'height:calc(100vh - 102px) !important;' : panrot.rot == 270 ? 'height:calc(100vh - 102px) !important;' : ''
                  }`"
                  :src="btmdata.uploadPAN ? btmdata.uploadPAN : ''"
                  :width="panrot.rot == 0 || panrot.rot == 180 ? '80%' : '100%'"
                  :alt="btmdata.uploadPAN"
                />

                <div v-if="btmdata.pan_is != 'y'" style="position: fixed !important; top: 112px; right: 8px; display: grid !important">
                  <v-btn @click="panrot.rot != 270 ? (panrot.rot = panrot.rot + 90) : (panrot.rot = 0)" fab small color="#2a2a2a"><v-icon color="#fff">mdi-rotate-right</v-icon></v-btn>
                  <v-btn class="mt-2" @click="panrot.pad = panrot.pad + 16" fab small color="#2a2a2a"><v-icon color="#fff">mdi-minus</v-icon></v-btn>
                  <v-btn class="mt-2" @click="panrot.pad != 0 ? (panrot.pad = panrot.pad - 16) : 0" fab small color="#2a2a2a"><v-icon color="#fff">mdi-plus</v-icon></v-btn>
                  <v-btn class="mt-2" @click="panrot.flip == 0 ? (panrot.flip = 1) : (panrot.flip = 0)" fab small color="#2a2a2a"
                    ><v-icon color="#fff">{{ panrot.flip == 0 ? "mdi-rotate-left-variant" : "mdi-rotate-right-variant" }}</v-icon></v-btn
                  >
                  <v-btn class="mt-2" @click="(panrot.flip = 1), (panrot.rot = 0), (panrot.pad = 0)" fab small color="#fff"><v-icon color="#000">mdi-close</v-icon></v-btn>
                </div>
              </v-tab-item>
              <v-tab-item>
                <iframe style="border: thin dashed #cfd8dc !important; border-radius: 0; height: calc(100vh - 102px)" :src="btmdata.adhar_file ? btmdata.adhar_file : ''" width="100%"></iframe>
              </v-tab-item>
              <v-tab-item style="position: relative">
                <div v-if="btmdata.uploadBankproof == '' && btmdata.incometype == 'Bank statement'" class="pt-6">
                  <p>In the segment stage, the income-proof; <b>Bank statement</b> is uploaded, so bank proof is not required.</p>
                  <iframe v-if="btmdata.income_is == 'y'" style="border: none; border-radius: 0; height: calc(100vh - 216px)" :src="btmdata.uploadFinancialproof ? btmdata.uploadFinancialproof : ''" width="100%"></iframe>
                  <img
                    v-else
                    :style="`transform: rotate(${incrot.rot}deg) scaleX(${incrot.flip == 0 ? -1 : 1}) !important; padding: ${incrot.pad / 3}px ${incrot.pad}px !important; ${
                      incrot.rot == 90 ? 'height:calc(100vh - 102px) !important;' : incrot.rot == 270 ? 'height:calc(100vh - 102px) !important;' : ''
                    }`"
                    :src="btmdata.uploadFinancialproof ? btmdata.uploadFinancialproof : ''"
                    :width="incrot.rot == 0 || incrot.rot == 180 ? '80%' : '100%'"
                    :alt="btmdata.uploadFinancialproof"
                  />

                  <div v-if="btmdata.income_is != 'y'" style="position: fixed !important; top: 112px; right: 8px; display: grid !important">
                    <v-btn @click="incrot.rot != 270 ? (incrot.rot = incrot.rot + 90) : (incrot.rot = 0)" fab small color="#2a2a2a"><v-icon color="#fff">mdi-rotate-right</v-icon></v-btn>
                    <v-btn class="mt-2" @click="incrot.pad = incrot.pad + 16" fab small color="#2a2a2a"><v-icon color="#fff">mdi-minus</v-icon></v-btn>
                    <v-btn class="mt-2" @click="incrot.pad != 0 ? (incrot.pad = incrot.pad - 16) : 0" fab small color="#2a2a2a"><v-icon color="#fff">mdi-plus</v-icon></v-btn>
                    <v-btn class="mt-2" @click="incrot.flip == 0 ? (incrot.flip = 1) : (incrot.flip = 0)" fab small color="#2a2a2a"
                      ><v-icon color="#fff">{{ incrot.flip == 0 ? "mdi-rotate-left-variant" : "mdi-rotate-right-variant" }}</v-icon></v-btn
                    >
                    <v-btn class="mt-2" @click="(incrot.flip = 1), (incrot.rot = 0), (incrot.pad = 0)" fab small color="#fff"><v-icon color="#000">mdi-close</v-icon></v-btn>
                  </div>
                </div>
                <div v-else>
                  <iframe v-if="btmdata.bank_is == 'y'" style="border: none; border-radius: 0; height: calc(100vh - 102px)" :src="btmdata.uploadBankproof ? btmdata.uploadBankproof : ''" width="100%"></iframe>
                  <img
                    v-else
                    :style="`transform: rotate(${bankrot.rot}deg) scaleX(${bankrot.flip == 0 ? -1 : 1}) !important; padding: ${bankrot.pad / 3}px ${bankrot.pad}px !important; ${
                      bankrot.rot == 90 ? 'height:calc(100vh - 102px) !important;' : bankrot.rot == 270 ? 'height:calc(100vh - 102px) !important;' : ''
                    }`"
                    :src="btmdata.uploadBankproof ? btmdata.uploadBankproof : ''"
                    :width="bankrot.rot == 0 || bankrot.rot == 180 ? '80%' : '100%'"
                    :alt="btmdata.uploadBankproof"
                  />
                  <div v-if="btmdata.bank_is != 'y'" style="position: fixed !important; top: 112px; right: 8px; display: grid !important">
                    <v-btn @click="bankrot.rot != 270 ? (bankrot.rot = bankrot.rot + 90) : (bankrot.rot = 0)" fab small color="#2a2a2a"><v-icon color="#fff">mdi-rotate-right</v-icon></v-btn>
                    <v-btn class="mt-2" @click="bankrot.pad = bankrot.pad + 16" fab small color="#2a2a2a"><v-icon color="#fff">mdi-minus</v-icon></v-btn>
                    <v-btn class="mt-2" @click="bankrot.pad != 0 ? (bankrot.pad = bankrot.pad - 16) : 0" fab small color="#2a2a2a"><v-icon color="#fff">mdi-plus</v-icon></v-btn>
                    <v-btn class="mt-2" @click="bankrot.flip == 0 ? (bankrot.flip = 1) : (bankrot.flip = 0)" fab small color="#2a2a2a"
                      ><v-icon color="#fff">{{ bankrot.flip == 0 ? "mdi-rotate-left-variant" : "mdi-rotate-right-variant" }}</v-icon></v-btn
                    >
                    <v-btn class="mt-2" @click="(bankrot.flip = 1), (bankrot.rot = 0), (bankrot.pad = 0)" fab small color="#fff"><v-icon color="#000">mdi-close</v-icon></v-btn>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item style="position: relative">
                <div v-if="btmdata.segment == 'Cash,F&O,Currency,Commodity' && btmdata.uploadFinancialproof == '' && btmdata.bankProofType == 'Bank Statement' && btmdata.data_type.cam_segis == true">
                  <p class="pa-8">CAMS status failed; in the bank stage, the bank-proof <b>Bank statement</b> is uploaded, so income proof is not required.</p>
                  <iframe v-if="btmdata.bank_is == 'y'" style="border: none; border-radius: 0; height: calc(100vh - 216px)" :src="btmdata.uploadBankproof ? btmdata.uploadBankproof : ''" width="100%"></iframe>
                  <img
                    v-else
                    :style="`transform: rotate(${bankrot.rot}deg) scaleX(${bankrot.flip == 0 ? -1 : 1}) !important; padding: ${bankrot.pad / 3}px ${bankrot.pad}px !important; ${
                      bankrot.rot == 90 ? 'height:calc(100vh - 102px) !important;' : bankrot.rot == 270 ? 'height:calc(100vh - 102px) !important;' : ''
                    }`"
                    :src="btmdata.uploadBankproof ? btmdata.uploadBankproof : ''"
                    :width="bankrot.rot == 0 || bankrot.rot == 180 ? '80%' : '100%'"
                    :alt="btmdata.uploadBankproof"
                  />
                  <div v-if="btmdata.bank_is != 'y'" style="position: fixed !important; top: 112px; right: 8px; display: grid !important">
                    <v-btn @click="bankrot.rot != 270 ? (bankrot.rot = bankrot.rot + 90) : (bankrot.rot = 0)" fab small color="#2a2a2a"><v-icon color="#fff">mdi-rotate-right</v-icon></v-btn>
                    <v-btn class="mt-2" @click="bankrot.pad = bankrot.pad + 16" fab small color="#2a2a2a"><v-icon color="#fff">mdi-minus</v-icon></v-btn>
                    <v-btn class="mt-2" @click="bankrot.pad != 0 ? (bankrot.pad = bankrot.pad - 16) : 0" fab small color="#2a2a2a"><v-icon color="#fff">mdi-plus</v-icon></v-btn>
                    <v-btn class="mt-2" @click="bankrot.flip == 0 ? (bankrot.flip = 1) : (bankrot.flip = 0)" fab small color="#2a2a2a"
                      ><v-icon color="#fff">{{ bankrot.flip == 0 ? "mdi-rotate-left-variant" : "mdi-rotate-right-variant" }}</v-icon></v-btn
                    >
                    <v-btn class="mt-2" @click="(bankrot.flip = 1), (bankrot.rot = 0), (bankrot.pad = 0)" fab small color="#fff"><v-icon color="#000">mdi-close</v-icon></v-btn>
                  </div>
                </div>
                <div v-else-if="btmdata.fno != 'Yes'" class="py-16">
                  <p>
                    In the segment stage, the Future and Options is <b>{{ btmdata.fno ? btmdata.fno : "-" }}</b
                    >, so proof is not required.
                  </p>
                </div>
                <div v-else>
                  <iframe v-if="btmdata.income_is == 'y'" style="border: none; border-radius: 0; height: calc(100vh - 102px)" :src="btmdata.uploadFinancialproof ? btmdata.uploadFinancialproof : ''" width="100%"></iframe>
                  <img
                    v-else
                    :style="`transform: rotate(${incrot.rot}deg) scaleX(${incrot.flip == 0 ? -1 : 1}) !important; padding: ${incrot.pad / 3}px ${incrot.pad}px !important; ${
                      incrot.rot == 90 ? 'height:calc(100vh - 102px) !important;' : incrot.rot == 270 ? 'height:calc(100vh - 102px) !important;' : ''
                    }`"
                    :src="btmdata.uploadFinancialproof ? btmdata.uploadFinancialproof : ''"
                    :width="incrot.rot == 0 || incrot.rot == 180 ? '80%' : '100%'"
                    :alt="btmdata.uploadFinancialproof"
                  />

                  <div v-if="btmdata.income_is != 'y'" style="position: fixed !important; top: 112px; right: 8px; display: grid !important">
                    <v-btn @click="incrot.rot != 270 ? (incrot.rot = incrot.rot + 90) : (incrot.rot = 0)" fab small color="#2a2a2a"><v-icon color="#fff">mdi-rotate-right</v-icon></v-btn>
                    <v-btn class="mt-2" @click="incrot.pad = incrot.pad + 16" fab small color="#2a2a2a"><v-icon color="#fff">mdi-minus</v-icon></v-btn>
                    <v-btn class="mt-2" @click="incrot.pad != 0 ? (incrot.pad = incrot.pad - 16) : 0" fab small color="#2a2a2a"><v-icon color="#fff">mdi-plus</v-icon></v-btn>
                    <v-btn class="mt-2" @click="incrot.flip == 0 ? (incrot.flip = 1) : (incrot.flip = 0)" fab small color="#2a2a2a"
                      ><v-icon color="#fff">{{ incrot.flip == 0 ? "mdi-rotate-left-variant" : "mdi-rotate-right-variant" }}</v-icon></v-btn
                    >
                    <v-btn class="mt-2" @click="(incrot.flip = 1), (incrot.rot = 0), (incrot.pad = 0)" fab small color="#fff"><v-icon color="#000">mdi-close</v-icon></v-btn>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item style="position: relative">
                <iframe v-if="btmdata.data_type && btmdata.data_type.signtype == 'digital'" style="border: none; border-radius: 0; height: calc(100vh - 102px)" :src="btmdata.uploadSignature ? btmdata.uploadSignature : ''" width="100%" height="500px"></iframe>

                <img
                  v-else
                  :style="`transform: rotate(${sigrot.rot}deg) scaleX(${sigrot.flip == 0 ? -1 : 1}) !important; padding: ${sigrot.pad / 3}px ${sigrot.pad}px !important; ${
                    sigrot.rot == 90 ? 'height:calc(100vh - 102px) !important;' : sigrot.rot == 270 ? 'height:calc(100vh - 102px) !important;' : ''
                  }`"
                  :src="btmdata.uploadSignature ? btmdata.uploadSignature : ''"
                  :width="sigrot.rot == 0 || sigrot.rot == 180 ? '80%' : '100%'"
                  :alt="btmdata.uploadSignature"
                />

                <div v-if="btmdata.data_type && btmdata.data_type.signtype != 'digital'" style="position: fixed !important; top: 112px; right: 8px; display: grid !important">
                  <v-btn @click="sigrot.rot != 270 ? (sigrot.rot = sigrot.rot + 90) : (sigrot.rot = 0)" fab small color="#2a2a2a"><v-icon color="#fff">mdi-rotate-right</v-icon></v-btn>
                  <v-btn class="mt-2" @click="sigrot.pad = sigrot.pad + 16" fab small color="#2a2a2a"><v-icon color="#fff">mdi-minus</v-icon></v-btn>
                  <v-btn class="mt-2" @click="sigrot.pad != 0 ? (sigrot.pad = sigrot.pad - 16) : 0" fab small color="#2a2a2a"><v-icon color="#fff">mdi-plus</v-icon></v-btn>
                  <v-btn class="mt-2" @click="sigrot.flip == 0 ? (sigrot.flip = 1) : (sigrot.flip = 0)" fab small color="#2a2a2a"
                    ><v-icon color="#fff">{{ sigrot.flip == 0 ? "mdi-rotate-left-variant" : "mdi-rotate-right-variant" }}</v-icon></v-btn
                  >
                  <v-btn class="mt-2" @click="(sigrot.flip = 1), (sigrot.rot = 0), (sigrot.pad = 0)" fab small color="#fff"><v-icon color="#000">mdi-close</v-icon></v-btn>
                </div>
              </v-tab-item>
              <v-tab-item>
                <iframe style="border: thin dashed #cfd8dc !important; border-radius: 0; height: calc(100vh - 102px)" :src="btmdata.clientimage ? btmdata.clientimage : ''" width="100%"></iframe>
              </v-tab-item>
              <v-tab-item>
                <iframe v-if="btmdata.KRA_file_path" style="border: none; border-radius: 0; height: calc(100vh - 102px)" :src="btmdata.KRA_file_path ? btmdata.KRA_file_path : ''" width="100%"></iframe>
                <p class="py-16" v-else>Completely verify all stages, and then only KRA will generate</p>
              </v-tab-item>
              <v-tab-item class="py-16">
                <v-btn class="text-none my-4 elevation-0" target="_blank" :disabled="btmdata.aadharxmlfile ? false : true" :href="btmdata.aadharxmlfile">Download Aadhar XML</v-btn>
              </v-tab-item>
              <v-tab-item class="pa-6 text-left">
                <div v-if="btmdata.data_type">
                  <v-row v-for="(i, o, p) in btmdata" :key="p" no-glutters>
                    <v-col cols="4">{{ o }}</v-col>
                    <v-col cols="8">{{ i }}</v-col>
                  </v-row>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
      <v-footer style="position: absolute; width: 100%; bottom: 0; overflow: hidden !important">
        <v-row>
          <v-col v-if="btmltab <= 9" style="border-right: 1px solid #ddd !important" cols="4" :class="btmdata.appv == 'y' ? 'pa-0' : btmdata.appv == 'n' ? 'pa-0' : 'pa-3'">
            <v-card v-if="btmdata.appv == 'y' || btmdata.appv == 'n'" width="100%" :color="btmdata.appv == 'y' ? 'green' : 'red'" class="text-center pt-5 pb-3 elevation-0 rounded-0 white--text font-weight-medium">
              <v-icon color="white" size="28" class="mr-3">{{ btmdata.appv == "y" ? "mdi-checkbox-multiple-marked-circle-outline" : "mdi-close-circle-outline" }}</v-icon>
              <span>{{ btmdata.sts }} stage, as {{ btmdata.appv == "y" ? "Approved" : "Rejected" }}</span>
            </v-card>
          </v-col>
          <v-col class="px-0 py-2" :cols="btmltab <= 9 ? '8' : '12'">
            <v-toolbar dense flat color="transparent">
              <p class="grey--text mb-0 text-left">
                App status : <span class="black--text font-weight-bold">{{ btmdata.app_status ? btmdata.app_status : "-" }}</span>
                <span v-if="tab == 2">
                  reason : <span class="black--text font-weight-bold">{{ btmdata.rejected_reason ? btmdata.rejected_reason : "-" }}</span>
                </span>
              </p>
              <v-spacer></v-spacer>
              <v-btn @click="getSingledata(btmdata.mobile_no)" color="warning" fab small><v-icon>mdi-refresh</v-icon></v-btn>
              <v-btn @click="closeAll()" color="error" class="ml-4" fab small><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
          </v-col>
        </v-row>
      </v-footer>
    </v-sheet>
    <v-overlay :value="bottomload" class="rounded-0">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import api from "../api.js";
import axios from "axios";

export default {
  data: () => ({
    snackbar: false,
    snackbarclr: "default",
    snackmsgbar: "",
    tab: 0,
    btmltab: 0,
    btmrtab: 0,
    mainloader: true,
    btmsheet: false,
    btmdata: [],

    status: null,
    request: null,
    stagewise: {},
    bottomload: false,
    kraform: null,

    panrot: {
      rot: 0,
      pad: 0,
      flip: 1,
    },
    bankrot: {
      rot: 0,
      pad: 0,
      flip: 1,
    },
    incrot: {
      rot: 0,
      pad: 0,
      flip: 1,
    },
    sigrot: {
      rot: 0,
      pad: 0,
      flip: 1,
    },
  }),
  created() {
    var url = new URL(window.location.href);
    var m = url.searchParams.get("bfbug");
    // let ls = localStorage.getItem('ls');
    // let ss = sessionStorage.getItem('ss');
    if (m) {
      localStorage.setItem("hash", btoa(m));
      this.getSingledata(m);
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    closeAll() {
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push("/");
    },
    stageFind() {
      if (this.btmltab == 0 && this.stagewise) {
        this.btmdata["appv"] = this.stagewise.mobile == "yes" ? "y" : this.stagewise.mobile == "no" ? "n" : null;
        this.btmdata["sts"] = "Mobile";
      } else if (this.btmltab == 1 && this.stagewise) {
        this.btmdata["appv"] = this.stagewise.email == "yes" ? "y" : this.stagewise.email == "no" ? "n" : null;
        this.btmdata["sts"] = "Email";
      } else if (this.btmltab == 2 && this.stagewise) {
        this.btmdata["appv"] = this.stagewise.pan == "yes" ? "y" : this.stagewise.pan == "no" ? "n" : null;
        this.btmdata["sts"] = "PAN";
      } else if (this.btmltab == 3 && this.stagewise) {
        this.btmdata["appv"] = this.stagewise.digilocker == "yes" ? "y" : this.stagewise.digilocker == "no" ? "n" : null;
        this.btmdata["sts"] = "Digilocker";
      } else if (this.btmltab == 4 && this.stagewise) {
        this.btmdata["appv"] = this.stagewise.personal == "yes" ? "y" : this.stagewise.personal == "no" ? "n" : null;
        this.btmdata["sts"] = "Personal";
      } else if (this.btmltab == 5 && this.stagewise) {
        this.btmdata["appv"] = this.stagewise.bank == "yes" ? "y" : this.stagewise.bank == "no" ? "n" : null;
        this.btmdata["sts"] = "Bank";
      } else if (this.btmltab == 6 && this.stagewise) {
        this.btmdata["appv"] = this.stagewise.segment == "yes" ? "y" : this.stagewise.segment == "no" ? "n" : null;
        this.btmdata["sts"] = "Segment";
      } else if (this.btmltab == 7 && this.stagewise) {
        this.btmdata["appv"] = this.stagewise.signature == "yes" ? "y" : this.stagewise.signature == "no" ? "n" : null;
        this.btmdata["sts"] = "Signature";
      } else if (this.btmltab == 8 && this.stagewise) {
        this.btmdata["appv"] = this.stagewise.selfie == "yes" ? "y" : this.stagewise.selfie == "no" ? "n" : null;
        this.btmdata["sts"] = "Selfie";
      } else if (this.btmltab == 9 && this.stagewise) {
        this.btmdata["appv"] = this.stagewise.nominee == "yes" ? "y" : this.stagewise.nominee == "no" ? "n" : null;
        this.btmdata["sts"] = "Nominee";
      }
    },
    getSingledata(item) {
      this.bottomload = true;

      let oconfig = {
        method: "post",
        url: `${api.api_url}/viewdata?mobileNo=${item}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      let axiosThis = this;

      axios
        .request(oconfig)
        .then((response) => {
          if (Object.keys(response.data).length > 1) {
            let data = response.data;
            data.KRA_file_path = data.KRA_file_path ? `${api.api_img}/static/${data.KRA_file_path.split("/static/")[1]}` : "";
            data.adhar_file = data.adhar_file ? `${api.api_img}/static/${data.adhar_file.split("/static/")[1]}` : "";
            data.clientimage = data.clientimage ? `${api.api_img}/static/${data.clientimage.split("/static/")[1]}` : "";
            data.e_signed_pdf_path = data.e_signed_pdf_path ? `${api.api_img}/static/${data.e_signed_pdf_path.split("/static/")[1]}` : "";
            data.uploadBankproof = data.uploadBankproof ? `${api.api_img}/static/${data.uploadBankproof.split("/static/")[1]}` : "";
            data.uploadFinancialproof = data.uploadFinancialproof ? `${api.api_img}/static/${data.uploadFinancialproof.split("/static/")[1]}` : "";
            data.uploadPAN = data.uploadPAN ? `${api.api_img}/static/${data.uploadPAN.split("/static/")[1]}` : "";
            data.uploadSignature = data.uploadSignature ? `${api.api_img}/static/${data.uploadSignature.split("/static/")[1]}` : "";
            data.aadharxmlfile = data.aadharxmlfile ? `${api.api_img}/static/${data.aadharxmlfile.split("/static/")[1]}` : "";

            data["adhar_is"] = data.adhar_file ? (data.adhar_file.split(".").slice(-1)[0] == "pdf" ? "y" : "n") : "";
            data["bank_is"] = data.uploadBankproof ? (data.uploadBankproof.split(".").slice(-1)[0] == "pdf" ? "y" : "n") : "";
            data["income_is"] = data.uploadFinancialproof ? (data.uploadFinancialproof.split(".").slice(-1)[0] == "pdf" ? "y" : "n") : "";

            if ("pandownresp" in response.data && response.data["pandownresp"]) {
              var panresponse = JSON.parse(response.data["pandownresp"]);

              if ("pan" in panresponse && panresponse["pan"] != true) {
                data["pan_is"] = "n";
              } else {
                data["pan_is"] = "y";
              }
            } else {
              data["pan_is"] = "n";
            }
            if (data["activestatus"]) {
              var pennyresponse = JSON.parse(data["activestatus"]);
              data["pennyresponse"] = pennyresponse;
            } else {
              data["pennyresponse"] = "";
            }
            axiosThis.btmdata = data;

            let datas = JSON.stringify({
              reason: "",
              log: "",
            });

            let config = {
              method: "post",
              url: `${api.api_url}/stage_update?mobileNo=${item}`,
              headers: {
                "Content-Type": "application/json",
              },
              data: datas,
            };

            axios
              .request(config)
              .then((response) => {
                if (Object.keys(response.data).length > 1) {
                  if (item.app_status == "re-e-signed completed" || item.app_status == "e-signed completed" || item.app_status == "Manually updated" || item.app_status == "approved") {
                    axiosThis.getKraDetails(axiosThis.btmdata);
                  }

                  axiosThis.stagewise = response.data;
                  axiosThis.btmltab = 0;
                  axiosThis.btmrtab = 0;
                  axiosThis.btmsheet = true;
                  document.body.style.zoom = "100%";
                  axiosThis.stageFind();
                  if (axiosThis.kraform) {
                    axiosThis.btmltab = 11;
                    axiosThis.btmrtab = 7;
                  }
                }
                axiosThis.kraform = null;
                axiosThis.bottomload = false;
              })
              .catch((error) => {
                console.log(error);
                axiosThis.bottomload = false;
                axiosThis.snackbar = true;
                axiosThis.snackbarclr = "red";
                axiosThis.snackmsgbar = error.message ? error.message : error;
              });
          } else {
            axiosThis.snackbar = true;
            axiosThis.snackbarclr = "red";
            axiosThis.snackmsgbar = response.data.msg ? response.data.msg : response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.bottomload = false;
          axiosThis.snackbar = true;
          axiosThis.snackbarclr = "red";
          axiosThis.snackmsgbar = error.message ? error.message : error;
        });
    },
    getKraDetails(v) {
      let data = JSON.stringify({
        dob: `${v.adhar_dob.slice(0, 2)}/${v.adhar_dob.slice(3, 5)}/${v.adhar_dob.slice(6)}`,
        pan: v.client_pan,
      });

      let config = {
        method: "post",
        url: `${api.api_url}/kraService/getKRAPANDetails`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data && response.data.personal_details && response.data.personal_details.KYC_STATUS != "NO DATA FOUND") {
            axiosThis.btmdata["kradata"] = response.data;
          } else {
            axiosThis.btmdata["kradata"] = "";
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.snackbar = true;
          axiosThis.snackbarclr = "red";
          axiosThis.snackmsgbar = error.message ? `KRA service; ${error.message}` : `KRA service; ${error}`;
        });
    },
  },
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px !important;
}

.exp-sty {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;

  /* border-top: none !important; */
}
</style>
