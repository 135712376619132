<template>
  <div>
    <v-row v-if="dataloader" no-glutters class="body-row">
      <v-col cols="12" md="7" class="left-cols1 pr-md-8">
        <p class="subtitle-2 font-weight-bold mb-2 mb-md-5 txt-666">
          <v-btn :disabled="cardloader" to="/pan" icon><v-icon>mdi-arrow-left</v-icon></v-btn> Step 2 of 6
        </p>
        <p class="headline font-weight-black mb-2">Complete the KYC via Digilocker</p>
        <p class="subtitle-1 font-weight-regular txt-666">Share PAN card and Aadhaar details</p>

        <v-card width="100%" class="text-center pt-8 elevation-0" color="transparent">
          <img src="@/assets/digi-main.svg" alt="" />
          <!-- 
                    <video style="height: 190px !important;
    object-fit: cover !important" controls loop width="100%" autoplay muted>
                        <source src="@/assets/digio.mp4" type="video/mp4">
                    </video> -->

          <p class="subtitle-1 font-weight-regular txt-666 mt-10 mb-6" style="line-height: 20px">
            Government recognised paperless storage for all documents. <br />
            "DigiLocker is a digital locker facility provided by the government".
          </p>

          <v-card outlined color="transparent" style="border: 1px solid #fb8c00 !important" class="rounded-xl pa-4 text-center mb-6">
            <p class="text-uppercase font-weight-bold mb-0">important!</p>
            <p class="mb-2">Please <b>select all</b> the documents on the <b>Digilocker consent</b> screen.</p>

            <v-card outlined class="rounded-xl">
              <img src="@/assets/digio-c.svg" width="100%" alt="" />
            </v-card>
          </v-card>

          <v-btn height="48px" color="#000" class="white--text text-none rounded-pill elevation-0 mb-4 px-8" :disabled="cardloader" :loading="cardloader" @click="connectDigio()"
            ><img class="mr-2" width="20px" src="@/assets/digi-btn.svg" /><span class="white--text subtitle-1 d-none d-md-flex font-weight-medium">Click to fetch documents from Digi Locker</span>
            <span class="white--text caption d-md-none font-weight-medium">Click to fetch documents from Digi Locker</span>
          </v-btn>
          <p class="subtitle-1 font-weight-regular txt-666">Don't have digilocker account? <a target="_blank" href="https://www.digilocker.gov.in/signup" style="text-decoration: none; color: #0037b7"> Click here</a></p>
          <!-- <v-row no-glutters>
                        <v-col class="pr-0">
                            <v-divider class="mt-3"></v-divider>
                        </v-col>
                        <v-col cols="1" class="px-0">
                            <span class="subtitle-2 txt-999">or</span>
                        </v-col>
                        <v-col class="pl-0">
                            <v-divider class="mt-3"></v-divider>
                        </v-col>
                    </v-row>
                    <v-btn height="48px" color="#666" text class="text-none rounded-pill elevation-0 mt-6 px-8"
                        style="border: 2px solid #666 !important;"><img class="mr-2" width="20px"
                            src="@/assets/Keyboard.svg" /><span class="txt-666 subtitle-1 font-weight-medium">No i want to
                            do it manually</span></v-btn> -->
        </v-card>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img src="@/assets/onb2.svg" class="rgt-img" alt="rgt-img" />
        </v-container>
      </v-col>
    </v-row>
    <v-overlay :value="cardloader" class="rounded-0">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
        <p class="font-weight-bold fs-18 white--text mt-2">Link your DigiLocker account with Zebu...</p>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/api";

export default {
  data: () => ({
    cardloader: false,
    dataloader: false,
  }),
  created() {
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        let p = response.data.data_type;
        if (
          response.data.data_type &&
          (p.stage == "digilocker" || p.stage == "personal" || p.stage == "bank" || p.stage == "segment" || p.stage == "declartaion" || p.stage == "signature" || p.stage == "selfie" || p.stage == "panproof" || p.stage == "bankproof" || p.stage == "nominee" || p.stage == "submit")
        ) {
          axiosThis.$emit("clicked", 2, response.data);
          if (response.data.digilockerVerify == "yes") {
            axiosThis.dataloader = false;
            axiosThis.$router.push("/digilocker");
          } else {
            axiosThis.dataloader = true;
          }
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },
  methods: {
    connectDigio() {
      this.cardloader = true;
      var stateid = "signup" + Math.random().toString(36).substring(7);
      sessionStorage.setItem("state", stateid);
      var config = {
        method: "post",
        url: api.api_url + "/digiLocker-transactionid?mob=" + atob(localStorage.getItem("hash")) + "&id=" + stateid,
        headers: {},
      };
      var axiosThis = this;
      axios(config)
        .then(function () {
          // C86AC39E

          window.location.href = "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=DED7DF29&state=" + stateid + ""; // live
          // window.location.href = "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=692DFD73&state=" + stateid + ""; // local

          // axiosThis.cardloader = false;
        })
        .catch(function (error) {
          console.log(error);
          axiosThis.cardloader = false;
          alert("Server is not reachable, please try again later. \n  For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com");
          axiosThis.$emit("snack", 0, error);
        });
      // this.$router.push('/digilocker')
    },
  },
};
</script>
