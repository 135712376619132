import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutView from '../components/LayoutView.vue'
import MobileSrc from '../views/MobileSrc.vue'
import EmailSrc from '../views/EmailSrc.vue'
import OnboardSrc from '../views/OnboardSrc.vue'
import DigiconnectSrc from '../views/DigiconnectSrc.vue'
import DigilockerSrc from '../views/DigilockerSrc.vue'
import PanSrc from '../views/PanSrc.vue'
import PersonalSrc from '../views/PersonalSrc.vue'
import BankSrc from '../views/BankSrcRPD.vue'
import SegmentSrc from '../views/SegmentSrcIF.vue'
import DeclartaionSrc from '../views/DeclartaionSrc.vue'
import SignVerifySrc from '../views/SignVerifySrc.vue'
import SelfieVerifysrc from '../views/SelfieVerifysrc.vue'
import BankproofSrc from '../views/BankproofSrc.vue'
import PanproofSrc from '../views/PanproofSrc.vue'
import NomineeSrc from '../views/NomineeSrc.vue'
import SubmitSrc from '../views/SubmitSrc.vue'
import CompleteSrc from '../views/CompleteSrc.vue'

import UidTesting from '../views/UidTesting.vue'

import TermsnCondition from '../views/TermsnCondition.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    redirect: 'LayoutView',
    name: 'layout',
    component: LayoutView,
    children: [
      {
        path: '/',
        name: 'mobile',
        component: MobileSrc
      },
      {
        path: '/email',
        name: 'email',
        component: EmailSrc
      },
      {
        path: '/onboarding',
        name: 'onboard',
        component: OnboardSrc
      },
      {
        path: '/pan',
        name: 'pan',
        component: PanSrc
      },
      {
        path: '/digilockerconnect',
        name: 'digilocker connect screen',
        component: DigiconnectSrc
      },
      {
        path: '/digilocker',
        name: 'digilocker',
        component: DigilockerSrc
      },
      {
        path: '/personal',
        name: 'personal',
        component: PersonalSrc
      },
      {
        path: '/bank',
        name: 'bank',
        component: BankSrc
      },
      {
        path: '/segment',
        name: 'segment',
        component: SegmentSrc
      },
      {
        path: '/declartaion',
        name: 'declartaion',
        component: DeclartaionSrc
      },
      {
        path: '/signature',
        name: 'signature',
        component: SignVerifySrc
      },
      {
        path: '/selfie',
        name: 'selfie',
        component: SelfieVerifysrc
      },
      {
        path: '/bankproof',
        name: 'bankproof',
        component: BankproofSrc
      },
      {
        path: '/panupload',
        name: 'panproof',
        component: PanproofSrc
      },
      {
        path: '/nominee',
        name: 'nominee',
        component: NomineeSrc
      },
      {
        path: '/submit',
        name: 'submit',
        component: SubmitSrc
      },
      {
        path: '/completed',
        name: 'complete',
        component: CompleteSrc
      },
    ]
  },
  {
    path: '/uid',
    name: 'uid testing',
    component: UidTesting
  },
  {
    path: '/tnc',
    name: 'Terms and Condition',
    component: TermsnCondition
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
