<template>
  <div>
    <v-row no-glutters class="body-rows">
      <v-col cols="12" md="7" class="left-cols1 pr-md-8">
        <p class="subtitle-2 font-weight-bold mb-2 mb-md-5 txt-666">
          <v-btn :disabled="cardloader" to="/bank" icon><v-icon>mdi-arrow-left</v-icon></v-btn> Step 4 of 6
        </p>
        <p class="headline font-weight-black mb-2">{{ segis == 0 ? "Upload" : "Uploaded" }} bank proof</p>
        <p class="subtitle-1 font-weight-regular txt-666">Upload a bank proof where the name matches the one on your PAN card</p>
        <v-alert v-if="rejectdata.app_status == 'rejected' ? rejectdata.stage_wise_approval.bankproof == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
          <span class="subtitle-2 font-weight-regular"
            >Your Bank proof have been rejected due <b>{{ rejectdata.bank_reject_reason }}</b></span
          >
        </v-alert>

        <div v-if="segis == 0 && !dataloader" class="pt-2">
          <div class="mt-4">
            <v-card v-for="(y, u) in bankprooflists" :key="u" @click="(BankProofselect = y.ini), (segis = 1)" style="border: 1px solid #ddd !important" color="transparent" class="pa-4 elevation-0 rounded-lg mb-6">
              <v-row no-glutters>
                <v-col cols="10" sm="11">
                  <p class="font-weight-medium fs-18 mb-0">{{ y.txt }}</p>
                  <!-- <v-chip color="#66BB6A"
                                            text-color="#fff" class="rounded-md px-1 ml-2" x-small
                                            label>Recommended</v-chip> -->
                </v-col>
                <v-col cols="2" sm="1">
                  <v-radio-group class="radio-b" hide-details v-model="BankProofselect" column>
                    <v-radio @click="BankProofselect = y.ini" label="" color="#000000" value="bs"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </div>
        <div v-else-if="segis == 1 && !dataloader" class="pt-4">
          <div v-if="!bankFile">
            <div class="form-group">
              <v-card color="rgba(246, 247, 247, 0.5)" @ondragover="onChange" :class="dragging ? 'dropAreaDragging' : ''" @dragenter="dragging = true" @dragend="dragging = false" @dragleave="dragging = false" class="fileupload elevation-0 rounded-lg text-center" style="border: 1px dashed #d0d3d6">
                <div class="innerfilup">
                  <img class="mb-2" src="@/assets/file drop.svg" width="48px" />
                  <p class="font-weight-bold fs-18" style="color: #0037b7 !important">Click here to upload</p>
                  <p class="mb-0 txt-666 subtitle-1 font-weight-regular" style="line-height: 18px">
                    You can drop your {{ BankProofselect == "pc" ? `Personalized Cheque` : BankProofselect == "bs" ? `Bank Statement` : BankProofselect == "bp" ? `Bank Passbook` : BankProofselect == "bl" ? `Form 16B` : null }} here to be uploaded
                  </p>
                </div>
                <input type="file" id="items" accept="application/pdf, image/png, image/jpg, image/jpeg" name="items[]" @change="onChange" />
              </v-card>
            </div>
          </div>

          <div v-else>
            <p class="mb-2">
              <span class="txt-666">Proof type</span> : <b>{{ BankProofselect == "pc" ? "Personalized Cheque" : BankProofselect == "bs" ? "Bank Statement" : BankProofselect == "bp" ? "Bank Passbook" : BankProofselect == "bl" ? "Bank Letter" : null }}</b>
            </p>
            <p>
              <span class="txt-666">File names</span> : <b>{{ bankimagedata.name }}</b>
            </p>
            <iframe style="border: thin dashed #cfd8dc !important; border-radius: 8px" :src="bankimage" width="100%" height="240"></iframe>

            <div v-if="bankPassRequired" class="pb-4">
              <p class="font-weight-medium fs-17 mb-0 mt-4">
                Your {{ BankProofselect == "pc" ? "Personalized Cheque" : BankProofselect == "bs" ? "Bank Statement" : BankProofselect == "bp" ? "Bank Passbook" : BankProofselect == "bl" ? "Bank Letter" : null }} file is protected.
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                  <path
                    d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                    fill="#EF5350"
                  />
                </svg>
              </p>
              <v-text-field
                :color="errorbankpass ? 'red' : ''"
                :error="errorbankpass"
                :readonly="bankpasserror == 'T'"
                :type="showbankpass ? 'text' : 'password'"
                v-model="bankpass"
                prepend-icon=""
                hide-details
                @keyup.enter="verifyBankPassword()"
                placeholder="Enter your file password for Encryption"
              >
                <template #append>
                  <div class="d-inline-flex">
                    <v-icon @click="showbankpass = !showbankpass">{{ showbankpass ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
                    <v-btn text class="text-none" :loading="passloader" @click="bankpasserror != 'T' ? verifyBankPassword() : ''">
                      <v-icon size="18" v-if="bankpasserror != 'T'">mdi-send</v-icon>
                      <v-icon v-else size="18" color="#37A668">mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
              <p v-if="errorbankpass" style="color: red; font-size: 14px">{{ bankpasserror }}</p>
            </div>
          </div>
          <v-row no-glutters class="mt-4 d-none d-md-flex">
            <v-col cols="6" class="text-right">
              <v-btn height="48px" @click="(BankProofselect = ''), (segis = 0), bankDatac()" color="#444" outlined style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-6">Clear</span></v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn v-if="bankPassRequired == true ? (bankpasserror == 'T' ? true : false) : true" height="48px" :disabled="!bankFile || bankPassRequired == null" @click="bankreject ? toNext() : fileUpload()" :loading="cardloader" color="#000" class="white--text text-none rounded-pill elevation-0"
                ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
              >
              <v-btn v-else disabled height="48px" :loading="cardloader" color="#000" class="white--text text-none rounded-pill elevation-0"><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
            </v-col>
          </v-row>

          <v-footer padless style="position: absolute; bottom: 40px; left: 0; width: 100%" color="#fff" class="px-3 pt-3 pb-1 d-md-none">
            <v-row>
              <v-col cols="6">
                <v-btn height="48px" @click="(BankProofselect = ''), (segis = 0), bankDatac()" color="#444" outlined style="border: 2px solid #444 !important" block class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-6">Clear</span></v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  v-if="bankPassRequired == true ? (bankpasserror == 'T' ? true : false) : true"
                  height="48px"
                  :disabled="!bankFile || bankPassRequired == null"
                  @click="bankreject ? toNext() : fileUpload()"
                  :loading="cardloader"
                  color="#000"
                  block
                  class="white--text text-none rounded-pill elevation-0"
                  ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
                >
                <v-btn v-else disabled height="48px" :loading="cardloader" color="#000" class="white--text text-none rounded-pill elevation-0"><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
              </v-col>
            </v-row>
          </v-footer>
        </div>

        <div v-else>
          <v-card width="100%" class="text-center py-16 elevation-0 mt-10">
            <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
          </v-card>
        </div>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img src="@/assets/bnk2.svg" class="rgt-img" alt="rgt-img" />
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/api";
export default {
  data: () => ({
    name: "",

    fno: "",
    segis: 0,
    BankProofselect: "",
    /////////////////
    dragging: false,
    bankFile: "",
    bankreject: false,
    bankimage: "",
    bankimagedata: [],
    bankpass: "",
    cardloader: false,
    dataloader: null,
    proofupdate: [],
    //
    bankPassRequired: false,
    errorbankpass: true,
    showbankpass: false,
    bankpasserror: "",

    passloader: false,
    rejectdata: [],

    bankprooflists: [
      {txt: "Bank Statement", ini: "bs"},
      {txt: "Personalized Cheque", ini: "pc"},
      {txt: "Bank Passbook", ini: "bp"},
      {txt: "Bank Letter", ini: "bl"},
    ],
  }),

  created() {
    this.dataloader = true;
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        let p = response.data.data_type;
        if (response.data.data_type && response.data.stage_wise_approval.bankproof != "yes" && (p.stage == "bankproof" || p.stage == "segment" || p.stage == "declartaion" || p.stage == "signature" || p.stage == "nominee" || p.stage == "selfie" || p.stage == "submit")) {
          axiosThis.$emit("clicked", 4, response.data);
          response.data.app_status == "rejected" && response.data.stage_wise_approval.bankproof == "no" ? (axiosThis.rejectdata = response.data) : (axiosThis.rejectdata = []);
          if (response.data.stage_wise_approval.bankproof == "no" || response.data.data_type.bankproof != true) {
            let resp = response.data.stage_wise_approval.bankproof;
            var configq = {
              method: "post",
              url: api.api_url + "/check_file?mob=" + atob(localStorage.getItem("hash")),
              headers: {},
            };
            axios(configq)
              .then(function (response) {
                if (response.data != "no file data found") {
                  if (resp == "no" || response.data.incometype != "Bank Statement") {
                    axiosThis.fno = response.data.fno;
                    if (axiosThis.rejectdata.app_status == "rejected" && axiosThis.rejectdata.stage_wise_approval.bankproof == "no") {
                      axiosThis.bankreject = false;
                      axiosThis.BankProofselect = "";
                      axiosThis.dataloader = false;
                    } else if (response.data["Bank File"] == true) {
                      axiosThis.bankreject = response.data["Bank File"] == true ? true : false;

                      axiosThis.BankProofselect = response.data.bankProofType == "Personalized Cheque" ? "pc" : response.data.bankProofType == "Bank Statement" ? "bs" : response.data.bankProofType == "Bank Passbook" ? "bp" : response.data.bankProofType == "Bank Letter" ? "bl" : "";
                      let name = "BP";
                      let link = response.data["Bank"];
                      axiosThis.getFileis(name, link);
                      axiosThis.segis = 1;
                    } else {
                      axiosThis.dataloader = false;
                    }
                    if ("pan_Status" in response.data && response.data["pan_Status"]) {
                      var panresponse = JSON.parse(response.data["pan_Status"]);
                      if ("pan" in panresponse && panresponse["pan"] == true) {
                        axiosThis.proofupdate["panproof"] = true;
                      } else {
                        axiosThis.proofupdate["panproof"] = false;
                      }
                    }
                  } else {
                    axiosThis.toNext();
                  }
                } else {
                  axiosThis.dataloader = false;
                }
              })
              .catch(function (error) {
                console.log(error);
                axiosThis.$emit("snack", 0, error);
              });
          } else {
            axiosThis.toNext();
          }
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
          axiosThis.dataloader = false;
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },

  mounted() {},
  methods: {
    toNext() {
      this.$emit("changed", 0);
      if (this.rejectdata.app_status == "rejected" && this.rejectdata.stage_wise_approval.bankproof == "no") {
        this.$emit("steged", "bankproof");
      } else {
        this.$emit("clicked", "segment");
      }
    },
    bankDatac() {
      this.bankFile = "";
      this.bankimagedata = [];
      this.bankimage = "";
      this.bankreject = false;
      this.BankProofselect = "";
      this.segis = 0;
      this.dragging = false;
      this.$emit("changed", 4);
    },

    onChange(e) {
      let file = e.target.files[0] || e.dataTransfer.files[0];
      if ((file["type"] == "application/pdf" || file["type"] == "image/jpg" || file["type"] == "image/jpeg" || file["type"] == "image/png") && file.size <= 4000000) {
        this.bankFile = file;
        this.bankProofCheck();
        this.bankimage = window.URL.createObjectURL(this.bankFile);
        this.bankimagedata["name"] = this.bankFile.name;
        this.bankimagedata["size"] = (this.bankFile.size / 1048576).toFixed(1);
      } else {
        this.cardloader = false;
        this.$emit("snack", 2, file.size > 4000000 ? "Img size should be less than 4 MB!" : "Only png, jpg, jpeg and PDF file formats are supported.");
      }
    },
    getFileis(name, link) {
      let config = {
        method: "post",
        url: api.api_url + `/filsend?path=${link}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      var axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data) {
            let hash = atob(localStorage.getItem("hash"));

            if (name == "BP") {
              let url = `${api.api_img}/${link}`;
              let blob = new Blob([url]);
              let file = new File([blob], `${url.split(`${hash}/`)[1].split(".")[0]}`, {type: `image/${url.split(`${hash}/`)[1].split(".")[1]}`});
              axiosThis.bankimage = url;
              axiosThis.bankFile = file;
              axiosThis.bankimagedata["name"] = axiosThis.bankFile.name;
              axiosThis.bankimagedata["size"] = (axiosThis.bankFile.size / 1048576).toFixed(1);
              axiosThis.bankimagedata["from"] = "be";
            }
            axiosThis.dataloader = false;
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    bankProofCheck() {
      if (this.bankFile) {
        let formData = new FormData();
        formData.append("bankproof", this.bankFile);
        var axiosThis = this;
        axios
          .post(api.api_url + "/bankCheck/" + atob(localStorage.getItem("hash")), formData, {
            headers: {"Content-Type": "multipart/form-data"},
          })
          .then(function (response) {
            if (response.data["data"] == "pdferror") {
              axiosThis.bankFile = "";
              axiosThis.bankimagedata = [];
              axiosThis.bankimage = "";
              axiosThis.$emit("snack", 2, response.data.emsg ? response.data.emsg : response.data);
              axiosThis.bankPassRequired = null;
            } else if (response.data["data"] == true) {
              axiosThis.bankPassRequired = true;
            } else {
              axiosThis.bankPassRequired = false;
            }
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.$emit("snack", 0, error);
          });
      } else {
        axiosThis.bankPassRequired = false;
      }
    },
    verifyBankPassword() {
      if (this.bankpass) {
        this.passloader = true;
        let formData = new FormData();
        formData.append("bankproof", this.bankFile);
        var axiosThis = this;
        axios
          .post(api.api_url + "/verifyBankPassword/" + this.bankpass, formData, {
            headers: {"Content-Type": "multipart/form-data"},
          })
          .then(function (response) {
            axiosThis.passloader = false;
            if (response.data["data"] == false) {
              axiosThis.bankpasserror = "Invalid password";
              axiosThis.errorbankpass = true;
              return false;
            } else if (response.data["data"] == true) {
              axiosThis.errorbankpass = false;
              axiosThis.bankpasserror = "T";
              return true;
            } else {
              axiosThis.bankpasserror = "Password required";
              return true;
            }
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.passloader = false;
            axiosThis.$emit("snack", 0, error);
          });
      } else {
        axiosThis.bankpasserror = "Password required";
      }
    },
    fileUpload() {
      if (this.bankreject == false && this.bankFile && (this.bankFile["type"] == "application/pdf" || this.bankFile["type"] == "image/jpg" || this.bankFile["type"] == "image/jpeg" || this.bankFile["type"] == "image/png")) {
        this.cardloader = true;
        let formData = new FormData();

        let type = this.BankProofselect == "pc" ? "Personalized Cheque" : this.BankProofselect == "bs" ? "Bank Statement" : this.BankProofselect == "bp" ? "Bank Passbook" : this.BankProofselect == "bl" ? "Bank Letter" : "";
        if (this.bankreject == false && this.bankFile) {
          formData.append("bankproof", this.bankFile);
          formData.append("bankproofPassword", this.bankpass);
          formData.append("bankProofType", type);
        }
        var axiosThis = this;

        axios
          .post(api.api_url + `/uploadSignupUserFiles/` + atob(localStorage.getItem("hash")), formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            if (response.data["msg"] == "All Files Upload sucessfully") {
              axiosThis.toNext();
            } else {
              axiosThis.cardloader = false;
              axiosThis.$emit("snack", 2, response.data.msg ? response.data.msg : response.data);
            }
          })
          .catch(function (error) {
            axiosThis.cardloader = false;
            axiosThis.$emit("snack", 0, error);
          });
      } else {
        this.cardloader = false;
        this.$emit("snack", 0, "Only png, jpg, jpeg and PDF file formats are supported.");
      }
    },
  },
};
</script>
